<template>
  <BT-Blade-Items
    addBladeName="purchase-order"
    bladeName="purchasing-orders"
    :canRefresh="false"
    class="mx-auto"
    hideBladeHeader
    hideHeader
    :large="$vuetify.breakpoint.mobile"
    title="Ordering">
    <template v-slot:body>
      <BT-List-Endless
        canRefresh
        :canSearch="false"
        customURL="/get/Calendar"
        label="Ordering"
        :large="$vuetify.breakpoint.mobile"
        navigation="suppliers"
        :onAddAsync="() => { $router.push({ name: 'purchase-order', params: { id: 'new' }}) }"
        :onPullMoreAsync="pullMoreOrders"
        :onPullAsync="pullOrders"
        :small="!$vuetify.breakpoint.mobile">
        <template v-slot:toolbar-left>
          <BT-Menu icon="mdi-plus" title="Create New Order">
            <template v-slot>
              <v-list-item @click="$router.push({ name: 'purchase-order', params: { id: 'new' }})">
                <v-list-item-icon><v-icon>mdi-plus</v-icon></v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>Brand New</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item @click="$router.push({ name: 'purchase-order', params: { id: 'new', repeatLast: true }})">
                <v-list-item-icon><v-icon>mdi-repeat</v-icon></v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>Repeat Last Order</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item @click="selectStandingOrder">
                <v-list-item-icon><v-icon>mdi-file-refresh-outline</v-icon></v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>From Standing Order</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
          </BT-Menu>

        </template>
        <template v-slot:items="{ items, onIntersect }">
          <v-list dense>
            <v-list-item v-for="(calItem, inde) in items" :key="inde" class="px-0">
              <v-list-item-content>
                <v-row class="pt-1 mx-0 px-0">
                  <v-col cols="4" sm="3" md="2" class="d-flex align-center">
                    {{ calItem.tz | toDayOfWeek }}
                    {{ calItem.tz | toDayMonth }}
                  </v-col>
                  <v-col v-if="isLengthyArray(calItem.orderBy)" cols="8" sm="9" md="10" class="ma-0 pa-0">
                    <v-divider :key="inde + '-t'" class="my-1" />
                    <v-list-item v-for="(order, ind) in calItem.orderBy" :key="ind" @click="navToScheduledItem(order)" dense class="ml-0 pl-0">
                      <!-- <v-list-item-avatar rounded class="ma-0 mr-2 pa-0" size="25">
                        <v-img :src="companyLogoURL(order.sellerID)">
                            <template v-slot:placeholder><v-icon>mdi-account-outline</v-icon></template>
                        </v-img>
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title>
                          <span v-if="order.purchaseOrderID != null">#{{ order.customerOrderNumber }}</span>
                          <span v-else-if="order.standingOrderID != null">{{ order.standingOrderName }}</span>
                        </v-list-item-title>
                        <v-list-item-subtitle>
                          <span v-if="order.purchaseOrderID != null">Placed</span>
                          <span v-else-if="order.standingOrderID != null">Standing Order</span>
                          <span v-else-if="order.orderSlotID != null" style="opacity: 0.3;">Empty Slot</span>
                        </v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action v-if="order.deliverBy != null">
                        <v-list-item-action-text>Deliver On</v-list-item-action-text>
                        <v-list-item-action-text>{{ order.deliverBy | toDayMonth }}</v-list-item-action-text>
                      </v-list-item-action> -->
                      <v-list-item-avatar rounded class="ma-0 mr-2 pa-0" size="25" :style="order.purchaseOrderID == null && order.standingOrderID == null ? 'opacity: 0.4' : null">
                          <v-img :src="companyLogoURL(order.sellerID)">
                              <template v-slot:placeholder><v-icon>mdi-account-outline</v-icon></template>
                          </v-img>
                      </v-list-item-avatar>
                      <v-list-item-content v-if="order.purchaseOrder != null && order.purchaseOrder.isInactive">
                          <v-list-item-title>(Deleted By Supplier)</v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-content v-else-if="order.isCancelled">
                          <v-list-item-title class="error--text">(Cancelled)</v-list-item-title>
                          <v-list-item-subtitle>Order by {{ order.orderBy | toShortDateAndTime }}</v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-content v-else-if="order.purchaseOrderID != null">
                          <v-list-item-title><v-icon small left>mdi-note-text</v-icon>#{{ order.purchaseOrder.customerOrderNumber }}</v-list-item-title>
                          <v-list-item-subtitle>
                              Placed on {{ order.purchaseOrder.createdOn | toShortDateAndTime }}
                          </v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-content v-else-if="order.standingOrderID != null">
                          <v-list-item-title><v-icon small left>mdi-repeat-variant</v-icon>{{ order.standingOrder.standingOrderName }}</v-list-item-title>
                          <v-list-item-subtitle>Placed on {{ order.standingOrder.lastEditedOn | toShortDateAndTime }}</v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-content v-else-if="order.orderSlotID != null" style="opacity: 0.4">
                          <v-list-item-title>No Order Placed</v-list-item-title>
                          <v-list-item-subtitle>
                              Cut-off {{ order.orderBy | toRelativeTime }}
                          </v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action v-if="order.deliverBy != null" class="text-center" :style="order.purchaseOrderID == null && order.standingOrderID == null ? 'opacity: 0.4' : null">
                          <v-list-item-action-text>
                              <div>Deliver On</div>
                              <div>{{ order.deliverBy | toDayMonth }}</div>
                          </v-list-item-action-text>
                      </v-list-item-action>
                    </v-list-item>
                    <v-divider :key="inde + '-b'" class="my-1" />
                  </v-col>
                  <v-col v-if="!isLengthyArray(calItem.orderBy)" class="ma-0 pa-0">
                    <v-list-item dense class="ma-0 pa-0">
                      <v-list-item-content>
                        <v-list-item-subtitle>No Orders</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>
                </v-row>
              </v-list-item-content>
            </v-list-item>
            <div v-intersect="onIntersect" />
          </v-list>
        </template>
      </BT-List-Endless>
    </template>
  </BT-Blade-Items>
</template>

<script>
export default {
  name: "Ordering-List",
  components: {
    BTMenu: () => import('~components/BT-Menu.vue')
  },
  data: function() {
    return {
      dateFrom: null,
      dateTo: null,
    }
  },
  methods: {
    navToOrder(id) {
      if (id != null) {
        this.$router.push({ name: 'purchase-order', params: { id: id }});
      }
    },
    navToScheduledItem(item) {
        if (item.purchaseOrderID != null) {
            this.$router.push({ name: 'purchase-order', params: { id: item.purchaseOrderID }});
        }
        else if (item.standingOrderID != null) {
            this.$router.push({ name: 'purchase-order', params: {
                dueDate: item.deliverBy,
                id: 'new',
                standingOrderID: item.standingOrderID,
                agreementID: item.supplyAgreementID,
            } });
        }
        else {
            this.$router.push({ name: 'purchase-order', params: {
                dueDate: item.deliverBy,
                id: 'new',
                agreementID: item.supplyAgreementID
            }});
        }
    },
    async pullOrders(nav, paramObj, reset) {
      this.dateFrom = this.$BlitzIt.auth.getToday();
      this.dateTo = this.$BlitzIt.auth.getFuture(15);
      
      var res = await this.$BlitzIt.store.get('suppliers', null, { dateFrom: this.dateFrom, dateTo: this.dateTo }, reset, null, '/get/Calendar');

      var list = res.scheduleItems;

      var auth = this.$BlitzIt.auth;
      var dFrom = this.$BlitzIt.auth.formRawTZ(this.dateFrom);
      var dTo = this.$BlitzIt.auth.formRawTZ(this.dateTo);

      var dates = [];

      do {
        var orderByList = list.filter(z => z.orderBy != null && auth.formRawTZ(z.orderBy).hasSame(dFrom, 'day'));
        var deliverByList = list.filter(z => z.deliverBy != null && auth.formRawTZ(z.deliverBy).hasSame(dFrom, 'day'));
        
        dates.push({
          tz: dFrom.toISO(),
          utc: dFrom.toUTC().toString(),
          orderBy: orderByList,
          deliverBy: deliverByList
        });

        dFrom = dFrom.plus({ days: 1 });
      }
      while (dFrom <= dTo)

      this.dateFrom = dFrom.toUTC().toString();
      this.dateTo = dFrom.plus({ days: 14 }).toUTC().toString();

      return dates;
    },
    async pullMoreOrders(eList) {
      if (!this.isLengthyArray(eList)) {
        return [];
      }

      var dFrom = this.$BlitzIt.auth.formRawTZ(this.dateFrom);
      var dTo = this.$BlitzIt.auth.formRawTZ(this.dateTo);

      var res = await this.$BlitzIt.store.get('suppliers', null, { dateFrom: this.dateFrom, dateTo: this.dateTo }, true, null, '/get/Calendar');

      var list = res.scheduleItems;

      var auth = this.$BlitzIt.auth;

      do {
        var orderByList = list.filter(z => z.orderBy != null && auth.formRawTZ(z.orderBy).hasSame(dFrom, 'day'));
        var deliverByList = list.filter(z => z.deliverBy != null && auth.formRawTZ(z.deliverBy).hasSame(dFrom, 'day'));
        
        eList.push({
          tz: dFrom.toISO(),
          utc: dFrom.toUTC().toString(),
          orderBy: orderByList,
          deliverBy: deliverByList
        });

        dFrom = dFrom.plus({ days: 1 });
      }
      while (dFrom <= dTo)

      this.dateFrom = dFrom.toUTC().toString();
      this.dateTo = dFrom.plus({ days: 14 }).toUTC().toString();

      return eList;

      // var eDate = list[list.length - 1].tz;
      // var sDateTime = this.$BlitzIt.auth.formRawTZ(eDate);

      // var dates = [];

      // for (let index = 0; index < 14; index++) {
      //   dates.push({
      //     tz: sDateTime.toISO(),
      //     utc: sDateTime.toUTC().toString(),
      //     orders: []
      //   });

      //   sDateTime = sDateTime.plus({ days: 1 });
      // }

      // return dates;
    },
    async selectStandingOrder() {
      console.log('selecting std');
    }
  }
};
</script>